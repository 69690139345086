* {
    box-sizing: border-box;
}

body, html {
    padding: 0;
    margin: 0;
    color: #b8bbb6;
    height: 100%;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background: #2d3331;

}

a, a:visited, a:focus {
    text-decoration: none;
    color: #b8bbb6;
}

#root {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
}

.App, #root {
    /*height: 100%;*/
    height: 100vh;
}

a.active {
    color: white;
}